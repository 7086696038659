.CreateBoardColumn {
    min-width: 250px;
    max-width: 250px;
    margin: 0 5px 0px 5px;
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
}

.CreateBoardColumnTitle {
    text-align: center;
    color: #bdbdbd;
    border: 1px dashed #dedede;
    border-radius: 5px;
    padding: 10px;
    margin-top: 50px;
    cursor: pointer;
}

.AddColumnForm {
    margin-top: 55px;
}

.CreateBoardColumnInput {
    max-width: 170px;
}