.BoardColumn {
    flex: 0 0 auto;
    padding: 3px;
}

.BoardColumnChildren {
    min-height: calc(100vh - 265px);
    flex: 0 0 auto;
    border-right: 3px solid #f1f1f1;
}

/*Last feedback column before action items*/
.ColumnContainer:nth-last-child(3) .BoardColumnChildren  {
    border: none;
}

.BoardColumnNameWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    min-height: 100px;
    align-items: flex-end;
}

.BoardColumnName {
    text-align: center;
    color: #2f2f2f;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer;
    width: 100%;
    padding-right: 56px;
}

.BoardColumnDelete {
    visibility: hidden;
}

.BoardColumnNameWrapper:hover .BoardColumnDelete {
    visibility: visible;
}

.BoardColumnEdit {
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    min-height: 70px;
    align-items: flex-end;
    font-weight: initial;
}