.CreateFeedback {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.AddFeedbackContainer {
    display: flex;
    flex-direction: column;
}

.AddFeedbackButton {
    align-self: center;
}

.SubmitFeedbackButton {
}

.CancelFeedbackButton {
    margin-left: 5px !important;
}

.FeedbackButtons {
    margin-top: 10px;
    align-self: flex-end;
}

.FeedbackButtonsContainer {
    display: flex;
    flex-direction: column;
}