.CardText {
  white-space: pre-wrap;
  font-size: 16px !important;
  color: #3e3e3e !important;
}

.CardIcon {
  font-size: 21px !important;
  color: #909090;
}

.CardAvatar {
  //background-color: #2196f3 !important;
  width: 32px !important;
  height: 32px !important;
  font-size: 14px !important;
}