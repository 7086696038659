.TeamForm {
    margin-top: -45px;
    margin-left: 10px;
}

.TeamFormHeader {
    text-align: center;
}

.TeamFormMargin {
    margin-top: 5px;
}