.Comment {
    margin-top: 5px;
    background-color: #f1f1f1 !important;
}

.CommentActionButtons {
    margin-left: auto !important;
    margin-right: -6px; /* used to align with feedback buttons above*/
}

.CommentFooter {
    font-size: 14px;
    width: 100%;
}