.BoardColumnNameWrapper {
    display: flex;
}

.ActionItemsColumnName {
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    width: 100%;
    /*padding: 0 56px 0 56px;*/
}

.ActionItemsColumn {
    padding-top: 0px;
    margin: 0px;
    margin-left: -47px;
    background-color: #f6f6f6;
    border-radius: 5px;
    padding-bottom: 40px; /* leave enough room for Contact Us widget */
}

.ActionItemsColumn .BoardColumnChildren {
    margin-top: 3px;
    flex-direction: row;
    align-items: flex-end;
    border: none;
    background-color: #f6f6f6;
}

.ActionItemsColumn .BoardColumnNameWrapper {
    flex-direction: row;
    align-items: flex-end;
    min-height: 0px;
}