.firebaseui-idp-icon {
    height: 25px !important;
    width: 25px !important;
}

.firebaseui-idp-text {
    font-size: 16px !important;
}

.firebaseui-idp-button {
    min-width: 220px !important;
    min-height: 60px !important;
}

.firebaseui-idp-password {
    background-color: #2196f3 !important;
}

.firebaseui-id-submit {
    background-color: #2196f3 !important;
}

.SignInContainer {
    background-color: white;
}

.SignInPageTitle {
    font-weight: 500;
    text-align: center;
}