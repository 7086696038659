.TeamSetupTitle {
    text-align: center;
    margin-bottom: 30px;
}

.TeamSetupAvatar {
    margin: 0px auto;
}

.TeamFormMargin {
    margin-bottom: 15px;
}

.DeleteTeamButton {
    float: right;
    margin-right: 10px !important;
}

.TeamSelectorContainer {
    max-width: 650px;
    margin: 0px auto;
    padding-top: 20px;
    margin-top: 20px;
}

.CreateTeamButton {
    float: left;
    margin-top: -16px !important;
    margin-right: 100% !important;
    width: 160px
}

.TeamSelectorTabPanel {
    width: 500px;
}