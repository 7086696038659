
.Board {

}

.BoardHeader {
    display: flex;
    color: #2f2f2f;
}

.HeartsLeft {
    background-color: rgb(245, 245, 245);
    color: rgb(136, 136, 136);
    border-radius: 15px;
    max-width: 93px;
    padding: 10px;
    height: 25px;
    margin-top: 30px;
}

.SprintName {
    margin-top: 10px;
    padding: 10px;
    margin-left: 16px;
    color: #2f2f2f;
}

.SprintNameText {
    cursor: pointer;
    font-weight: 500;
}

.BoardNameEditInput {
    width: 350px;
}

.BoardNameEditField {
    margin-left: 70px;
    margin-right: 52px;
    align-self: center;
    height: 98px;
}

.BoardColumns {
    /*https://iamsteve.me/blog/entry/using-flexbox-for-horizontal-scrolling-navigation*/
    display: flex; /* [1] */
    flex-wrap: nowrap; /* [1] */
    overflow-x: auto; /* [2] */
}

.BoardActions {
    align-self: center;
    align-content: flex-end;
    margin-left: auto;
}

.DeleteBoard {
    /*margin-top: 24px;*/
    visibility: hidden;
    align-self: center;
}

.SprintNameContainer {
    align-self: center;
    display: flex;
}

.SprintNameContainer:hover .DeleteBoard {
    visibility: visible;
}

.DemoButton {
    align-self: center;
}