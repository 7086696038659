.ColumnsContainer {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    max-height: calc(100vh - 162px);
}

.ColumnContainer {
    display: flex;
    min-width: 275px;
    max-width: 400px;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
}

.AddColumnButton {
    height: 47px;
    position: relative;
    top: 0px;
    left: -57px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}